import GroupedBarChartHorizontal from "../charts/GroupedBarChartHorizontal";

const KeyFeatureApex = ({ data }: any) => {
  return (
    <div className="key-feature-apex" id={Object.keys(data.date)[0]}>
      <div className="p-8">
        <p className="title">{data?.date.title}</p>
        <GroupedBarChartHorizontal
          data={data?.date.Key_feature_adoption_rate_of_Ap.graph}
          height={700}
        />
        <p className="font-bold mt-12 capitalize">
          {data?.date.Key_feature_adoption_rate_of_Ap.R_S_N_key_ap.key + ": "}
        </p>
        <ul className="list-outside">
          {data?.date.Key_feature_adoption_rate_of_Ap.R_S_N_key_ap.data.map(
            (item: string, i: number) => (
              <li className="text-sm" key={i}>
                {item}
              </li>
            )
          )}
        </ul>
      </div>
      {/* <div
        className="blank-page"
        style={{ height: `${1123 * data?.blank ?? 0}px` }}
      ></div> */}
    </div>
  );
};

export default KeyFeatureApex;
