import { Doughnut } from "react-chartjs-2";

const DoughnutChat = ({ data, label }: any) => {
  const displayLabel = label === undefined ? true : label;
  const chartData = {
    label: data.label,
    datasets: [
      {
        data: data?.data,
        backgroundColor: data?.backgroundColor,
        label: data?.label,
        borderWidth: 0,
      },
    ],
  };
  return (
    <Doughnut
      data={chartData}
      options={{
        plugins: {
          datalabels: {
            display: displayLabel,
            anchor: "end",
            align: "start",
            formatter: (value: any, context: any) => {
              return value !== 0 ? value : "";
            },
            color: "#000000",
            font: {
              size: 20,
              weight:700,
            }
          },
          legend: {
            display: true,
            position: "top",
          },
        },
      }}
    />
  );
};

export default DoughnutChat;
