import BarChart from "../charts/BarChart";

const EndpointInventory = ({ data }: any) => {
  return (
    <div className="endpoint-inventory" id={Object.keys(data.date)[0]}>
      <div className="">
        <p className="title">Endpoint Inventory</p>
        <div className="w-full flex flex-row flex-nowrap items-center">
          <div className="w-3/5">
            <BarChart data={data?.date.ENDPOINT_INVENTORY.Bar_graph} />
          </div>
          <div className="w-2/5 pl-14">
            <p className="font-bold capitalize">
              {data?.date.ENDPOINT_INVENTORY.R_S_N_E_V.key + ":"}
            </p>
            <ul className="list-outside">
              {data?.date.ENDPOINT_INVENTORY.R_S_N_E_V.data.map(
                (item: string, i: number) => (
                  <li className="text-sm" key={i}>
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <p className="title">Connected Products and License Information</p>
        <ul>
          <li className="font-bold">License Information:</li>
        </ul>
        <table
          className="w-full border-spacing-1 mb-12 border-none border-separate"
          cellPadding={5}
          cellSpacing={5}
        >
          <thead>
            <tr className="bg-[#696969] text-white text-left text-base tracking-wide">
              <td className="p-4">Status</td>
              <td className="p-4">Product</td>
            </tr>
          </thead>
          <tbody className="bg-[#ededed] text-sm font-light">
            {data?.date.ENDPOINT_INVENTORY.CP_and_LI.License_Information.map(
              (item: any, i: number) => (
                <tr key={i}>
                  <td className="font-medium">{item.Status}</td>
                  <td className="font-medium">{item.Product}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <ul>
          <li className="font-bold">Products Connected:</li>
        </ul>
        <table
          className="w-full border-spacing-1 mb-12 border-none border-separate"
          cellPadding={5}
          cellSpacing={5}
        >
          <thead>
            <tr className="bg-[#696969] text-white text-left text-base tracking-wide">
              <td className="p-4">Status</td>
              <td className="p-4">Product</td>
            </tr>
          </thead>
          <tbody className="bg-[#ededed] text-sm font-light">
            {data?.date.ENDPOINT_INVENTORY.CP_and_LI.Products_Connected.map(
              (item: any, i: number) => (
                <tr key={i}>
                  <td className="font-medium">{item.Status}</td>
                  <td className="font-medium">{item.Product}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {/* <div
        className="blank-page"
        style={{ height: `${1123 * data?.blank ?? 0}px` }}
      ></div> */}
    </div>
  );
};

export default EndpointInventory;
