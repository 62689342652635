import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import { DASHBOARD, HOME } from "../constants/routeConstants";
import Dashboard from "../pages/Dashboard";
export default function Router() {
  return (
    <Routes>
      <Route path={HOME} element={<Home />} />
      <Route path={DASHBOARD} element={<Dashboard />} />
    </Routes>
  );
}
