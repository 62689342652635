import BarChart from "../charts/BarChart";

const SloSummary = ({ data }: any) => {
  return (
    <div className="slo-summary" id={Object.keys(data.date)[0]}>
      <div className="">
        <p className="title">SLO Summary</p>
        <BarChart data={data?.date.SLO_SUMMARY.graph} />
        <p className="title">SLO Details</p>
        <table
          className="w-full border-spacing-1 mb-12 border-none border-separate"
          cellPadding={5}
          cellSpacing={5}
        >
          <thead>
            <tr className="bg-[#696969] text-white text-left text-base tracking-wide">
              <th className="p-4">Priority</th>
              <th className="p-4">Description</th>
              <th className="p-4">Response Time</th>
            </tr>
          </thead>
          <tbody className="bg-[#ededed] text-sm font-light">
            {data?.date.SLO_SUMMARY.SLO_Details.map((item: any, i: number) => (
              <tr key={i}>
                <td className="font-medium">{item.Priority}</td>
                <td className="font-medium">{item.Description}</td>
                <td className="font-medium">{item.Response_Time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div
        className="blank-page"
        style={{ height: `${1123 * data?.blank ?? 0}px` }}
      ></div> */}
    </div>
  );
};

export default SloSummary;
