import DoughnutChat from "../charts/DoughnutChat";
import PieChart from "../charts/PieChart";
import moment from "moment";

const ExecutiveSummary = ({ data }: any) => {
  return (
    <div className="executive-summary" id={Object.keys(data.date)[0]}>
      <div className="">
        <p className="title">EXECUTIVE SUMMARY</p>
        <ul>
          <li className="text-sm mb-3">
            A total number of{" "}
            <strong>
              {data?.date.EXECUTIVE_SUMMARY.total_incidents.total_incidents}{" "}
              incidents
            </strong>{" "}
            were observed during the time frame of{" "}
            <strong>
              {moment(
                data?.date.EXECUTIVE_SUMMARY.total_incidents.start_date
              ).format("Do MMMM YYYY")}
            </strong>{" "}
            to{" "}
            <strong>
              {moment(
                data?.date.EXECUTIVE_SUMMARY.total_incidents.end_date
              ).format("Do MMMM YYYY")}
            </strong>
            .
          </li>
          <li>
            <strong>Risk Index:</strong>
            <table
              className="w-full border-none"
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr>
                  <td width="15%" className="p-5">
                    <div className="w-[150px] relative">
                      <DoughnutChat
                        data={
                          data?.date.EXECUTIVE_SUMMARY.risk_index.chart || ""
                        }
                        label={false}
                      />
                      <p className="absolute top-1/2 left-1/2 m-0 text-2xl font-normal -translate-x-1/2 -translate-y-1/2">
                        {data?.date.EXECUTIVE_SUMMARY.risk_index.chart.data[0]}%
                      </p>
                    </div>
                  </td>
                  <td width="40%" className="p-5">
                    <p className="m-0 text-justify italic text-black font-normal">
                      The Risk Index is a comprehensive score based on the
                      dynamic assessment of risk factors inclusive of exposure,
                      attack risk, and security configurations risk.
                    </p>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </li>
          <li className="text-sm mb-3">
            <strong>
              {data?.date.EXECUTIVE_SUMMARY.highly_exploitable} Highly
              exploitable unique CVEs
            </strong>{" "}
            in Endpoints.
          </li>
          <li className="text-sm mb-3">
            <strong>
              {data?.date.EXECUTIVE_SUMMARY.Incident_Closed} Incident
            </strong>{" "}
            Closed without customer resolution
          </li>
          <li className="mb-8 text-sm">
            <strong>
              Top {data?.date.EXECUTIVE_SUMMARY.top_incident.no_incidents}{" "}
              Incidents:
            </strong>{" "}
            Highest({data?.date.EXECUTIVE_SUMMARY.Highest_incidient}) Incidents
            triggered on
            <strong>
              {moment(
                data?.date.EXECUTIVE_SUMMARY.Highest_incidient_date
              ).format("Do MMMM YYYY")}
            </strong>
          </li>
        </ul>
        <table
          className="w-full border-spacing-1 mb-12 border-none border-separate"
          cellPadding={5}
          cellSpacing={5}
        >
          <thead>
            <tr className="bg-[#696969] text-white text-left text-sm tracking-wide">
              <th className="w-8/12 p-4">Incident Names</th>
              <th className="4/12 p-4">Data Source from V1</th>
            </tr>
          </thead>
          <tbody className="bg-[#ededed] text-sm font-light">
            {data?.date.EXECUTIVE_SUMMARY.top_incident?.table.map(
              (item: any, i: number) => (
                <tr key={i}>
                  <td className="p-4 font-medium">{item.incident_name}</td>
                  <td className="p-4 font-medium">{item.source}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <ul className="break-before-page">
          <li className="mt-12">
            <strong>Agent Life Cycle</strong>
          </li>
        </ul>
        <table className="w-full border-none" cellPadding={0} cellSpacing={0}>
          <tbody>
            <tr>
              <td width="35%">
                <div className="w-[170px] mx-auto">
                  {/* <canvas id="apexOne" /> */}
                  <PieChart
                    data={
                      data?.date.EXECUTIVE_SUMMARY.agent_life_cycle.apex_chart
                    }
                  />
                  <p className="text-sm text-center font-bold mt-3 capitalize">
                    {data?.date.EXECUTIVE_SUMMARY.agent_life_cycle.apex_chart.title
                      .split("_")
                      .join(" ")}
                  </p>
                </div>
              </td>
              <td width="35%">
                <div className="w-[170px] mx-auto">
                  {/* <canvas id="workloadSecurity" /> */}
                  <PieChart
                    data={
                      data?.date.EXECUTIVE_SUMMARY.agent_life_cycle
                        .workload_chart
                    }
                  />
                  <p className="text-sm text-center font-bold mt-3 capitalize">
                    {data?.date.EXECUTIVE_SUMMARY.agent_life_cycle.workload_chart.title
                      .split("_")
                      .join(" ")}
                  </p>
                </div>
              </td>
              <td width="30%">
                <p className="mt-5">
                  Please ensure that the most recent operating system version is
                  installed on all endpoints and servers to address any open
                  vulnerabilities and effectively detect real threats.
                </p>
              </td>
            </tr>
            <tr className="h-6"></tr>
          </tbody>
        </table>
        <ul>
          <li>
            <strong>
              Top {data?.date.EXECUTIVE_SUMMARY.top_endpoint.no_endpoint}{" "}
              Endpoint with Highest Observed Attack Technic Detection and
              Severity
            </strong>
          </li>
        </ul>
        <table
          className="w-full border-spacing-1 mb-6 border-none border-separate"
          cellPadding={5}
          cellSpacing={5}
        >
          <thead>
            <tr className="bg-[#696969] text-white text-left text-sm tracking-wide">
              <th className="w-2/5 p-4">Endpoint Name</th>
              <th className="w-1/5 p-4">Number of Detection with Severity</th>
              <th className="w-2/5 p-4">Action Taken from SOC Team</th>
            </tr>
          </thead>
          <tbody className="bg-[#ededed] text-sm font-light">
            {data?.date.EXECUTIVE_SUMMARY.top_endpoint?.top_incident_table.map(
              (item: any, i: number) => (
                <tr key={i}>
                  <td className="p-4 font-medium">{item.endpoint_name}</td>
                  <td className="p-4 font-medium">{item.no_of_detections}</td>
                  {i === 0 && (
                    <td
                      className="p-4 font-medium"
                      rowSpan={
                        data?.date.EXECUTIVE_SUMMARY.top_endpoint
                          ?.top_incident_table.length
                      }
                    >
                      {data?.date.EXECUTIVE_SUMMARY.top_endpoint?.action}
                    </td>
                  )}
                </tr>
              )
            )}
          </tbody>
        </table>
        <ul>
          <li className="text-sm">
            <strong>Endpoint Protection: Apex One as Service</strong> (Need to
            deploy apex one on remaining endpoint to decrease attack surface and
            improve overall security)
          </li>
        </ul>

        <div className="w-2/3 mx-auto mb-2">
          <table className="mx-auto">
            <thead>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="single-bar-chart">
                    <div
                      className="bar-progress"
                      style={{
                        width: `${
                          (data?.date.EXECUTIVE_SUMMARY.endpoint_protection
                            .data[0] /
                            data?.date.EXECUTIVE_SUMMARY.endpoint_protection
                              .data[1]) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-center font-bold mt-2">
                    {data?.date.EXECUTIVE_SUMMARY.endpoint_protection.data[0]} /{" "}
                    {data?.date.EXECUTIVE_SUMMARY.endpoint_protection.data[1]}{" "}
                    Agents Deployed
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <ul>
          <li className="text-sm">
            <strong>Endpoint Sensor: Trend V1 - XDR Endpoint Sensor</strong>{" "}
            (Kindly enable the endpoint sensor on remaining endpoints to
            increase the endpoint behavior visibility)
          </li>
        </ul>

        <div className="w-2/3 mx-auto mb-4">
          <table className="mx-auto">
            <thead>
              <tr>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="single-bar-chart">
                    <div
                      className="bar-progress"
                      style={{
                        width: `${
                          (data?.date.EXECUTIVE_SUMMARY.endpoint_sensor
                            .data[0] /
                            data?.date.EXECUTIVE_SUMMARY.endpoint_sensor
                              .data[1]) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-center font-bold mt-2">
                    {data?.date.EXECUTIVE_SUMMARY.endpoint_sensor.data[0]} /{" "}
                    {data?.date.EXECUTIVE_SUMMARY.endpoint_sensor.data[1]}{" "}
                    Agents Deployed
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <div
        className="blank-page"
        style={{ height: `${1123 * data?.blank ?? 0}px` }}
      ></div> */}
    </div>
  );
};

export default ExecutiveSummary;
