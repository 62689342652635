import { ChangeEvent, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import FirstPage from "../components/pdf-components/FirstPage";
// import { data } from "../data/data";
import TableOfContents from "../components/pdf-components/TableOfContents";
import ExecutiveSummary from "../components/pdf-components/ExecutiveSummary";
import ThreatIntelSummary from "../components/pdf-components/ThreatIntelSummary";
import PendingIncidentsSummary from "../components/pdf-components/PendingIncidentsSummary";
import SloSummary from "../components/pdf-components/SloSummary";
import EndpointInventory from "../components/pdf-components/EndpointInventory";
import KeyFeatureApex from "../components/pdf-components/KeyFeatureApex";
import KeyFeatureWorkLoad from "../components/pdf-components/KeyFeatureWorkLoad";
import axios from "axios";
import KeyFeatureWorDeepSecurity from "../components/pdf-components/KeyFeatureDeepSecurity";

// https://blog.risingstack.com/pdf-from-html-node-js-puppeteer/
// https://www.npmjs.com/package/pdf-puppeteer

// https://pdfkit.org/

interface ErrorData {
  [key: string]: {
    column_validation: {
      [key: string]: string;
    };
  };
}

// const initialValues = {
//   WEEKLY_REPORT: {
//     client_name: "Demo Bank",
//     start_date: "2024-02-12T00:00:00",
//     end_date: "2024-02-18T00:00:00",
//     blank: 0,
//   },
//   TABLE_OF_CONTENTS: {
//     date: {
//       TABLE_OF_CONTENTS: [
//         {
//           title: "Executive Summary",
//           page_no: 1,
//           link: "THREAT_INTEL_SUMMARY",
//         },
//         {
//           title: "Threat Intel Summary",
//           page_no: 3,
//           link: " ",
//         },
//         {
//           title: "Indicators of Compromise (IOC) Match Summary",
//           page_no: 3,
//           link: "PENDING_INCIDENTS_SUMMARY",
//         },
//         {
//           title: "Incident Summary by Severity",
//           page_no: 4,
//           link: " ",
//         },
//         {
//           title: "Incident Summary by Status",
//           page_no: 4,
//           link: " ",
//         },
//         {
//           title: "Incidents Summary by Priority",
//           page_no: 5,
//           link: " ",
//         },
//         {
//           title: "Top 10 Incidents Summary by Category",
//           page_no: 5,
//           link: " ",
//         },
//         {
//           title: "Pending Incident Summary",
//           page_no: 6,
//           link: "THREAT_INTEL_SUMMARY",
//         },
//         {
//           title: "SLO Summary",
//           page_no: 7,
//           link: " ",
//         },
//         {
//           title: "Endpoint Inventory",
//           page_no: 8,
//           link: " ",
//         },
//         {
//           title: "Connected Products and License Information",
//           page_no: 8,
//           link: " ",
//         },
//         {
//           title: "Key feature adoption rate of Apex One",
//           page_no: 9,
//           link: " ",
//         },
//         {
//           title: "Key feature adoption rate of Cloud One Workload Security",
//           page_no: 10,
//           link: " ",
//         },
//         {
//           title: "Key feature adoption rate of Deep Security",
//           page_no: 11,
//           link: " ",
//         },
//       ],
//       blank: 1,
//     },
//   },
//   EXECUTIVE_SUMMARY: {
//     date: {
//       EXECUTIVE_SUMMARY: {
//         total_incidents: {
//           total_incidents: 70,
//           start_date: "2024-02-12",
//           end_date: "2024-02-18",
//         },
//         risk_index: {
//           chart: {
//             data: [63, 37],
//             backgroundColor: ["rgb(255, 130, 0)", "rgb(105, 105, 105)"],
//             label: [],
//           },
//         },
//         highly_exploitable: 961,
//         top_incident: {
//           no_incidents: 5,
//           table: [
//             {
//               incident_name: "Process Execution without Parameter ",
//               source: "TM Endpoint Sensor",
//             },
//             {
//               incident_name: "Suspicious File Creation in Uncommon Folder ",
//               source: "TM Endpoint Sensor",
//             },
//             {
//               incident_name: "Network Discovery",
//               source: "TM Endpoint Sensor",
//             },
//             {
//               incident_name: "Cybercrime Malware",
//               source: "Trend Micro Apex One aaS",
//             },
//             {
//               incident_name:
//                 "Repetitive Execution of Rundll32 or Svchost without Parameter",
//               source: "TM Endpoint Sensor",
//             },
//           ],
//         },
//         agent_life_cycle: {
//           apex_chart: {
//             data: [30289, 173, 53],
//             backgroundColor: [["rgb(255, 130, 0)", "rgb(105, 105, 105)"]],
//             label: ["Latest Version", "Older Version", "End of Life"],
//           },
//           workload_chart: {
//             data: [5291, 190],
//             backgroundColor: [["rgb(255, 130, 0)", "rgb(105, 105, 105)"]],
//             label: ["Latest Version", "Older Version"],
//           },
//         },
//         top_endpoint: {
//           no_endpoint: 5,
//           top_incident_table: [
//             {
//               endpoint_name: "10.177.196.210(10.177.196.210)",
//               no_of_detections: "7274 Medium",
//             },
//             {
//               endpoint_name: "10.177.196.211(10.177.196.211)",
//               no_of_detections: "6850 Medium",
//             },
//             {
//               endpoint_name: "YBLTD4DQKG3(192.168.29.80)",
//               no_of_detections: "440 Medium",
//             },
//             {
//               endpoint_name: "YBLT64C4N93(10.203.7.227)",
//               no_of_detections: "302 Medium",
//             },
//             {
//               endpoint_name: "YBLT5CD1481R66(10.160.3.187)",
//               no_of_detections: "300 High",
//             },
//           ],
//           action:
//             "SOC Team working on created\nobserved attack techniques on\nmentioned endpoint including\nresponding and communicating with\nyour team based on formed\nworkbenches by OATs on severity.",
//         },
//         endpoint_protection: {
//           data: [35960, 38333],
//           label: [],
//         },
//         endpoint_sensor: {
//           data: [34348, 38332],
//           label: [],
//         },
//         R_S_N: {
//           key: "summary",
//           data: [
//             "Kindly Enable the Endpoint Snesor to Decrease the attack Surfce.",
//             "Kindly Install Endpoint Sensor to prortect the environment.",
//           ],
//         },
//       },
//       blank: 0,
//     },
//   },
//   THREAT_INTEL_SUMMARY: {
//     date: {
//       THREAT_INTEL_SUMMARY: {
//         Indicators_of_Compromise_IOC: {
//           Key: ["IP", "URL", "Hash", "Domain", "Email"],
//           data: [
//             {
//               label: "IOC Sweeped",
//               data: [41, 107, 260, 49, 0],
//               backgroundColor: "#e67700",
//             },
//             {
//               label: "IOC Matched",
//               data: [2, 0, 0, 0, 0],
//               backgroundColor: "#696969",
//             },
//           ],
//         },
//         R_S_N_Ioc: {
//           key: "notes",
//           data: [
//             "We recommend you to kindly block the matched IPs at your perimeter or gateway.",
//           ],
//         },
//         Matched_IOCs_Detailed_Summary: [
//           {
//             Sr_No: 1,
//             Advisory_Name: "Confluence RCE Vulnerability",
//             M_IOC_Type: "IP",
//             M_IOC_Detail: "\n183.196.214.38",
//             "No_of_End/Email": 1,
//           },
//           {
//             Sr_No: 2,
//             Advisory_Name: "Akira Ransomware",
//             M_IOC_Type: "IP",
//             M_IOC_Detail: "\n13.107.42.12",
//             "No_of_End/Email": 48,
//           },
//         ],
//         Incident_Summary_by_Severity: {
//           data: [0, 0, 42, 28],
//           label: ["Critical", "High", "Medium", "Low"],
//           backgroundColor: ["#ff8200", "#ffa950", "#ffd9b2"],
//         },
//         R_S_N_ISBS: {
//           key: "notes",
//           data: [
//             "An incident summary by\nseverity is categorizes and\ndescribes cybersecurity\nincidents based on their level\nof impact and potential harm.",
//             "In this week, No Critical and\nHigh Severity Incidents had\nbeen triggered. The\nmaximum incidents were\nMedium and Low Severity.\nThose incidents are Process\nExecution without\nParameter and Suspicious\nFile Creation in Uncommon\nFolder.",
//           ],
//         },
//         Incident_Summary_by_status: {
//           data: [60, 10],
//           label: ["Critical", "High"],
//           backgroundColor: ["#ff8200", "#ffa950", "#ffd9b2"],
//         },
//         R_S_N_ISB_Status: {
//           key: "summary",
//           data: [],
//         },
//         Incidents_Summary_by_Priority: {
//           Key: ["P1", "P2", "P3", "P4"],
//           data: [
//             {
//               label: "Closed",
//               data: [0, 0, 25, 35],
//               backgroundColor: "#ffcd9a",
//             },
//             {
//               label: "Pending from SOC",
//               data: [0, 0, 0, 0],
//               backgroundColor: "#ffc0cb",
//             },
//             {
//               label: "Pending from Customer",
//               data: [0, 0, 3, 7],
//               backgroundColor: "#ffa07a",
//             },
//           ],
//         },
//         R_S_N_ISBP: {
//           key: "recommendation",
//           data: [],
//         },
//         T10IS_by_Category: {
//           Key: [
//             "Process Execution without Parameter",
//             "Suspicious File Creation in Uncommon Folder",
//             "Network Discovery",
//             "Cybercrime Malware",
//             "Repetitive Execution of Rundll32 or Svchost without Parameter",
//           ],
//           data: [
//             {
//               label: "Closed",
//               data: [19, 16, 1, 6, 5],
//               backgroundColor: "#ffd9b2",
//             },
//             {
//               label: "Pending from SOC",
//               data: [0, 0, 0, 0, 0],
//               backgroundColor: "#ffa950",
//             },
//             {
//               label: "Pending from Customer",
//               data: [0, 0, 6, 0, 0],
//               backgroundColor: "#ffa950",
//             },
//           ],
//         },
//         R_S_N_T10IS: {
//           key: "notes",
//           data: [
//             "notes",
//             "An incident summary by Category is used to classify and arrange cybersecurity incidents\naccording to classifications.",
//             "As we observed that most of the incidents were triggered: Process Execution without\nParameter and Suspicious File Creation in Uncommon Folder.",
//             "Both the incidents had been closed as false positive and Remediated as per Yes Bank team\nConfirmation.",
//           ],
//         },
//       },
//       blank: 0,
//     },
//   },
//   PENDING_INCIDENTS_SUMMARY: {
//     date: {
//       PENDING_INCIDENTS_SUMMARY: {
//         T_P_Incidents: 4.0,
//         P_I_F_Customer: 10.0,
//         P_Incidents_from_SOC: 10.0,
//         Pie_chart: {
//           data: [10, 10, 0],
//           backgroundColor: ["#ff8200", "#ffa950", "#ffd9b2"],
//           label: [
//             "Total Pending Incidents",
//             "Pending Incidents from Customer",
//             "Pending Incidents from SOC Team",
//           ],
//         },
//         R_S_N_P_I_S: {
//           key: "notes",
//           data: [
//             "As the SOC team shared the incident to Ces Bank Team and Waiting for the\nresponse for the pending incident.",
//             "The Pending incident are CES11304, CES11281, CES11208, CES11329, CES11302, CES11287,\nCES11284, CES11212, CES11205 and CES11201.",
//           ],
//         },
//       },
//       blank: 0,
//     },
//   },
//   SLO_SUMMARY: {
//     date: {
//       SLO_SUMMARY: {
//         graph: {
//           Key: ["Total Closed Incidents", "SLO Met", "SLO Not Met"],
//           data: [60, 60, 0],
//           label: "Case volume",
//           backgroundColor: "#ff8200",
//         },
//         SLO_Details: [
//           {
//             Priority: 1,
//             Description:
//               "Incidents that have a severe impact on customer operations. This\nevent is a concern, such as attack formations or potential breaches",
//             Response_Time: "01 Hour",
//           },
//           {
//             Priority: 2,
//             Description:
//               "Incidents that have a significant impact, or the potential to have a severe impact, on operations.",
//             Response_Time: "04 Hours",
//           },
//           {
//             Priority: 3,
//             Description:
//               "Incidents that have a minimal impact with the potential for escalate if not contained causing significant impact on operations.",
//             Response_Time: "24 Hours",
//           },
//           {
//             Priority: 4,
//             Description:
//               "Incidents that do not have direct impact on Customer operations but violates Customer security Baseline.",
//             Response_Time: "48 Hours",
//           },
//         ],
//       },
//       blank: 0,
//     },
//   },
//   ENDPOINT_INVENTORY: {
//     date: {
//       ENDPOINT_INVENTORY: {
//         Bar_graph: {
//           Key: [
//             "XDR feature enabled",
//             "XDR feature not enabled",
//             "Action Required",
//           ],
//           data: [34382, 119, 121],
//           label: "Info",
//           backgroundColor: "#ff8200",
//         },
//         R_S_N_E_V: {
//           key: "recommendation",
//           data: [
//             "As we observed that in\n119 endpoints features are not\nenable and 121 endpoints\nrequired to perform some\nactions. So, kindly perform\nrequired action and enable\nthe features to decrease\nattack risk and secure the\ndigital assets.",
//           ],
//         },
//         CP_and_LI: {
//           License_Information: [
//             {
//               Status: "License Information:",
//               Product: "-",
//             },
//             {
//               Status: "Status",
//               Product: "Product",
//             },
//             {
//               Status: "Normal",
//               Product: "Trend Micro Apex One™ as a Service",
//             },
//             {
//               Status: "Normal",
//               Product: "Trend Micro Cloud One",
//             },
//           ],
//           Products_Connected: [
//             {
//               Status: "Connected",
//               Product: "Trend Micro Apex One as a Service",
//             },
//             {
//               Status: "Connected",
//               Product: "Trend Micro Deep Security Software",
//             },
//             {
//               Status: "Connected",
//               Product: "Trend Cloud One",
//             },
//           ],
//         },
//       },
//       blank: 0,
//     },
//   },
//   Key_feature_adoption_rate_of_Ap: {
//     date: {
//       Key_feature_adoption_rate_of_Ap: {
//         graph: {
//           Key: [
//             "-",
//             "Anti-malware Scans",
//             "Web Reputation",
//             "Behavior Monitoring",
//             "Predictive Machine Learning",
//             "Smart Feedback",
//             "Firewall",
//             "Suspicious Connection Service",
//           ],
//           data: [
//             {
//               label: "Case Volume",
//               data: [30495, 30495, 30495, 30495, 30495, 30495, 30495, 30495],
//               backgroundColor: "#ff8200",
//             },
//             {
//               label: "Case Volume",
//               data: [30088, 30097, 441, 30088, 30088, 30097, 185, 30088],
//               backgroundColor: "#696969",
//             },
//           ],
//         },
//         R_S_N_key_ap: {
//           key: "summary",
//           data: [
//             "We noticed that a significant number of endpoints currently do not have Web\nreputation enabled. Please activate Web reputation on the remaining endpoints to\nsafeguard our digital assets from potential threats originating from the internet.",
//           ],
//         },
//       },
//       blank: 0,
//       title: "Key feature adoption rate of Apex One",
//     },
//   },
//   Key_feature_adoption_rate_of_Cw: {
//     date: {
//       Key_feature_adoption_rate_of_Cw: {
//         graph: {
//           Key: [
//             "-",
//             "Anti-malware Scans",
//             "Web Reputation",
//             "Behavior Monitoring",
//             "Predictive Machine Learning",
//             "Smart Feedback",
//             "Firewall",
//             "Suspicious Connection Service",
//           ],
//           data: [
//             {
//               label: "Case Volume",
//               data: [30495, 30495, 30495, 30495, 30495, 30495, 30495, 30495],
//               backgroundColor: "#ff8200",
//             },
//             {
//               label: "Case Volume",
//               data: [30088, 30097, 441, 30088, 30088, 30097, 185, 30088],
//               backgroundColor: "#696969",
//             },
//           ],
//         },
//         R_S_N_key_cl: {
//           key: "summary",
//           data: [
//             "We noticed that a significant number of endpoints currently do not have Web\nreputation enabled. Please activate Web reputation on the remaining endpoints to\nsafeguard our digital assets from potential threats originating from the internet.",
//           ],
//         },
//       },
//       blank: 0,
//       title: "Key feature adoption rate of Cloud One Workload Security",
//     },
//   },
//   Key_feature_adoption_rate_of_Ds: {
//     date: {
//       Key_feature_adoption_rate_of_Ds: {
//         graph: {
//           Key: [
//             "-",
//             "Anti-malware Scans",
//             "Web Reputation",
//             "Behavior Monitoring",
//             "Predictive Machine Learning",
//             "Smart Feedback",
//             "Firewall",
//             "Suspicious Connection Service",
//           ],
//           data: [
//             {
//               label: "Case Volume",
//               data: [30495, 30495, 30495, 30495, 30495, 30495, 30495, 30495],
//               backgroundColor: "#ff8200",
//             },
//             {
//               label: "Case Volume",
//               data: [30088, 30097, 441, 30088, 30088, 30097, 185, 30088],
//               backgroundColor: "#696969",
//             },
//           ],
//         },
//         R_S_N_key_cl: {
//           key: "summary",
//           data: [
//             "We noticed that a significant number of endpoints currently do not have Web\nreputation enabled. Please activate Web reputation on the remaining endpoints to\nsafeguard our digital assets from potential threats originating from the internet.",
//           ],
//         },
//       },
//       blank: 0,
//       title: "Key feature adoption rate of Deep Security",
//     },
//   },
// };

const Dashboard = () => {
  const [reportData, setReportData] = useState<any>("");
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const handlePrint = () => {
    window.print();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setFile(selectedFile || null);
  };
  const handleUploadFile = async () => {
    setLoading(true);
    setReportData("");
    setError("");
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios
          .post(
            "https://eventus-api.digibranders.com/auto_report/regal_cinema_result",
            // "http://139.59.44.96/auto_report/regal_cinema_result",
            formData
          )
          .then((res) => {
            setReportData(res.data.data.value);
          })
          .catch((err) => {
            setError(err.response.data.data.result as ErrorData);
          });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (file) {
      handleUploadFile();
    }
  }, [file]);

  return (
    <div className="w-full">
      <Navbar />
      <div className="print-action">
        <form onSubmit={handleUploadFile}>
          <label htmlFor="file" className="btn-primary">
            Upload excel file{" "}
            <input
              type="file"
              id="file"
              name="file"
              accept=".xlsx"
              className="hidden"
              onChange={handleFileChange}
            />
          </label>
        </form>
        {/* <button className="btn-primary">Upload excel file</button> */}

        {reportData && (
          <button className="btn-primary" onClick={handlePrint}>
            Download Pdf
          </button>
        )}
      </div>
      {loading && (
        <div className="w-full h-screen grid place-items-center">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {error && (
        <div className="container">
          {Object.entries(error.sheet_detail).map(
            ([sheetName, validation]: any) => (
              <div key={sheetName}>
                <h2 className="mt-3 font-semibold">{sheetName}</h2>
                <ul>
                  {Object.entries(validation).map(
                    ([columnName, message]: any) => (
                      <li key={columnName}>
                        <strong>{columnName}</strong>{" "}
                        <ul className="ml-3 list-inside">
                          {Object.entries(message).map(([key, value]: any) => (
                            <li key={key}>
                              {key}:{" "}
                              {typeof value === "object" ? (
                                <ul>
                                  {Object.entries(value).map(
                                    ([nestedKey, nestedValue]: any) => (
                                      <li key={nestedKey}>
                                        {nestedKey}:{" "}
                                        {JSON.stringify(nestedValue)}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <p>{JSON.stringify(value)}</p>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )
          )}
        </div>
      )}

      {reportData && (
        <div className="print-section">
          {/* First page */}
          {reportData?.WEEKLY_REPORT && (
            <FirstPage data={reportData.WEEKLY_REPORT} />
          )}

          {/* Table of contents  */}
          {reportData?.TABLE_OF_CONTENTS && (
            <TableOfContents data={reportData.TABLE_OF_CONTENTS} />
          )}

          {/* Executive summary  */}
          {reportData?.EXECUTIVE_SUMMARY && (
            <ExecutiveSummary data={reportData.EXECUTIVE_SUMMARY} />
          )}

          {/* Threat intel summary   */}
          {reportData?.THREAT_INTEL_SUMMARY && (
            <ThreatIntelSummary data={reportData.THREAT_INTEL_SUMMARY} />
          )}

          {/* Pending incidents summary */}
          {reportData?.PENDING_INCIDENTS_SUMMARY && (
            <PendingIncidentsSummary
              data={reportData.PENDING_INCIDENTS_SUMMARY}
            />
          )}

          {/* SLO summary */}
          {reportData?.SLO_SUMMARY && (
            <SloSummary data={reportData.SLO_SUMMARY} />
          )}

          {/* Endpoint inventory */}
          {reportData?.ENDPOINT_INVENTORY && (
            <EndpointInventory data={reportData.ENDPOINT_INVENTORY} />
          )}

          {/* Key feature apex one */}
          {reportData?.Key_feature_adoption_rate_of_Ap && (
            <KeyFeatureApex data={reportData.Key_feature_adoption_rate_of_Ap} />
          )}

          {/* Key feature workload */}
          {reportData?.Key_feature_adoption_rate_of_Cw && (
            <KeyFeatureWorkLoad
              data={reportData.Key_feature_adoption_rate_of_Cw}
            />
          )}

          {/* Key feature deep security */}
          {reportData?.Key_feature_adoption_rate_of_Ds && (
            <KeyFeatureWorDeepSecurity
              data={reportData.Key_feature_adoption_rate_of_Ds}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
