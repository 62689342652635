// import { useEffect, useState } from "react";
import DoughnutChat from "../charts/DoughnutChat";
import GroupedBarChart from "../charts/GroupedBarChart";
import StackedHorizontalBarChart from "../charts/StackedHorizontalBarChart";

const ThreatIntelSummary = ({ data }: any) => {
  return (
    <div className="threat-intel-summary" id={Object.keys(data.date)[0]}>
      <div className="">
        <p className="title">THREAT INTEL SUMMARY</p>
        <p className="font-normal">
          It refers to the process of collecting, analyzing, and disseminating
          information about potential and current cyber threats.
        </p>
        <p className="title">INDICATORS OF COMPROMISE (IOC) MATCH SUMMARY</p>
        <div className="w-4/5 mx-auto">
          <GroupedBarChart
            data={data?.date.THREAT_INTEL_SUMMARY.Indicators_of_Compromise_IOC}
          />
        </div>

        <p className="font-bold capitalize">
          {data?.date.THREAT_INTEL_SUMMARY.R_S_N_Ioc.key}
        </p>
        <ul>
          {data?.date.THREAT_INTEL_SUMMARY.R_S_N_Ioc.data.length > 0 &&
            data?.date.THREAT_INTEL_SUMMARY.R_S_N_Ioc.data.map(
              (item: string, i: number) => (
                <li key={i} className="text-sm">
                  {item}
                </li>
              )
            )}
        </ul>
        <p className="title">MATCHED IOCs DETAILED SUMMARY</p>
        <table
          className="w-full border-spacing-1 mb-12 border-none border-separate"
          cellPadding={5}
          cellSpacing={5}
        >
          <thead>
            <tr className="bg-[#696969] text-white text-left text-base tracking-wide">
              <th className="p-4">Sr. No.</th>
              <th className="p-4">Advisory Name</th>
              <th className="p-4">Matched IOC Type</th>
              <th className="p-4">Matched IOC Details</th>
              <th className="p-4">No of Endpoints / Email</th>
            </tr>
          </thead>
          <tbody className="bg-[#ededed] text-sm font-light">
            {data?.date.THREAT_INTEL_SUMMARY.Matched_IOCs_Detailed_Summary.map(
              (item: any, i: number) => (
                <tr key={i}>
                  <td className="p-4 font-medium">{item.Sr_No}</td>
                  <td className="p-4 font-medium">{item.Advisory_Name}</td>
                  <td className="p-4 font-medium">{item.M_IOC_Type}</td>
                  <td className="p-4 font-medium">{item.M_IOC_Detail}</td>
                  <td className="p-4 font-medium">{item["No_of_End/Email"]}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <p className="title break-before-page">INCIDENTS SUMMARY BY SEVERITY</p>
        <div className="w-full grid grid-flow-row grid-cols-2 items-center gap-6">
          <div>
            <DoughnutChat
              data={
                data?.date.THREAT_INTEL_SUMMARY.Incident_Summary_by_Severity
              }
            />
          </div>
          <div className="pl-10">
            <p className="font-bold capitalize">
              {data?.date.THREAT_INTEL_SUMMARY.R_S_N_ISBS.key}
            </p>
            <ul className="list-outside">
              {data?.date.THREAT_INTEL_SUMMARY.R_S_N_ISBS.data.map(
                (item: string, i: number) => (
                  <li key={i} className="text-sm">
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <p className="title">Incident Summary by status</p>
        <div className="w-full grid grid-flow-row grid-cols-2 items-center gap-6">
          <div>
            <DoughnutChat
              data={data?.date.THREAT_INTEL_SUMMARY.Incident_Summary_by_status}
            />
          </div>
          <div className="pl-10">
            <p className="font-bold capitalize">
              {data?.date.THREAT_INTEL_SUMMARY.R_S_N_ISB_Status.key}
            </p>
            <ul className="list-outside">
              {data?.date.THREAT_INTEL_SUMMARY.R_S_N_ISB_Status.data.map(
                (item: string, i: number) => (
                  <li key={i} className="text-sm">
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <p className="title break-before-page">INCIDENTS SUMMARY BY PRIORITY</p>
        <div className="w-full flex flex-row items-center">
          <div className="w-3/5">
            <GroupedBarChart
              data={
                data?.date.THREAT_INTEL_SUMMARY.Incidents_Summary_by_Priority
              }
            />
          </div>
          <div className="w-2/5 pl-14">
            <p className="font-bold capitalize">
              {data?.date.THREAT_INTEL_SUMMARY.R_S_N_ISBP.key}
            </p>
            <ul className="list-outside">
              {data?.date.THREAT_INTEL_SUMMARY.R_S_N_ISBP.data.map(
                (item: string, i: number) => (
                  <li key={i} className="text-sm">
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <p className="title">Top 10 Incidents Summary by Category</p>
        <StackedHorizontalBarChart
          data={data?.date.THREAT_INTEL_SUMMARY.T10IS_by_Category}
        />
        <p className="font-bold capitalize">
          {data?.date.THREAT_INTEL_SUMMARY.R_S_N_T10IS.key}
        </p>
        <ul>
          {data?.date.THREAT_INTEL_SUMMARY.R_S_N_T10IS.data.map(
            (item: string, i: number) => (
              <li key={i} className="text-sm">
                {item}
              </li>
            )
          )}
        </ul>
      </div>
      {/* <div
        className="blank-page"
        style={{ height: `${1123 * data?.blank ?? 0}px` }}
      ></div> */}
    </div>
  );
};

export default ThreatIntelSummary;
