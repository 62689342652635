import DoughnutChat from "../charts/DoughnutChat";

const PendingIncidentsSummary = ({ data }: any) => {
  return (
    <div className="pending-incidents-summary" id={Object.keys(data.date)[0]}>
      <div className="">
        <p className="title">Pending IncidentS Summary</p>
        <ul className="font-bold">
          <li>
            Total Pending Incidents:{" "}
            {data?.date.PENDING_INCIDENTS_SUMMARY.T_P_Incidents}
          </li>
          <li>
            Pending Incidents from Customer:{" "}
            {data?.date.PENDING_INCIDENTS_SUMMARY.P_I_F_Customer}
          </li>
          <li>
            Pending Incidents from SOC Team:{" "}
            {data?.date.PENDING_INCIDENTS_SUMMARY.P_Incidents_from_SOC}
          </li>
        </ul>

        <div className="w-1/2 mx-auto">
          <div className="w-[250px] mx-auto">
            <DoughnutChat
              data={data?.date.PENDING_INCIDENTS_SUMMARY.Pie_chart}
            />
          </div>
        </div>
        <p className="font-bold my-8 capitalize">
          {data?.date.PENDING_INCIDENTS_SUMMARY.R_S_N_P_I_S.key}
        </p>
        <ul className="list-outside">
          {data?.date.PENDING_INCIDENTS_SUMMARY.R_S_N_P_I_S.data.map(
            (item: string, i: number) => (
              <li key={i} className="text-sm">
                {item}
              </li>
            )
          )}
        </ul>
      </div>
      {/* <div
        className="blank-page"
        style={{ height: `${1123 * data?.blank ?? 0}px` }}
      ></div> */}
    </div>
  );
};

export default PendingIncidentsSummary;
